
























































































































































































































































































































import {Component, Provide, Vue} from 'vue-property-decorator';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import { CrInput, CrSelect, CrDatePicker, CrCheckbox, CrTable, CrTableBody, CrTableHead, CrTitle, CrTextArea, CrButton, CrAlert, CrLoadingSpinner } from "@pxc/crisp-oss-ui-components";

@Component({
  components: {
    HelloWorld,
    CrInput, CrSelect, CrDatePicker, CrCheckbox, CrTable, CrTableBody, CrTableHead, CrTitle, CrTextArea, CrButton, CrAlert, CrLoadingSpinner
  },

  data() {
    return {
      myInput: "input value",
      privacy: false,
      contact: false,

      anlagenOptions: [
        {
          label: "Erzeugungsanlage",
          value: "Erzeugungsanlage"
        },
        {
          label: "Bezugsanlage",
          value: "Bezugsanlage"
        },
        {
          label: "Mischanlage",
          value: "Mischanlage"
        }
      ],
      kurzschlussmelder: [
        {
          label: "Bauseits vorhanden",
          value: "Bauseits vorhanden"
        },
        {
          label: "keine Kurzschlussmelder",
          value: "keine Kurzschlussmelder"
        }
      ],
      hersteller: [
        {
          label: "Horstmann",
          value: "Horstmann"
        },
        {
          label: "Kriess",
          value: "Kriess"
        },
      ],
      kommunikationsprotokoll: [
        {
          label: "ModbusRTU",
          value: "ModbusRTU"
        },
        {
          label: "ModbusTCP",
          value: "ModbusTCP"
        },
        {
          label: "Analog verdrahtet",
          value: "Analog verdrahtet"
        }
      ],
      lsAnsteuerung: [
        {
          label: "Über Kurzschlussmelder",
          value: "Über Kurzschlussmelder"
        },
        {
          label: "Über Fernwirkeinheit",
          value: "Über Fernwirkeinheit"
        }
      ],
      ezaRegler: [
        {
          label: "Bauseits vorhanden",
          value: "Bauseits vorhanden"
        },
        {
          label: "Bauseits beigestellt",
          value: "Bauseits beigestellt"
        },
        {
          label: "Durch Phoenix Contact zu liefern",
          value: "Durch Phoenix Contact zu liefern"
        },
      ],
      hilfsSpannung: [
        {
          label: "Wird bauseits gepuffert zur Verfügung gestellt",
          value: "Wird bauseits gepuffert zur Verfügung gestellt"
        },
        {
          label: "Wird im Schrank produziert und gepuffert",
          value: "Wird im Schrank produziert und gepuffert"
        },
        {
          label: "Wird im Schrank produziert und gepuffert und für extern zur Verfügung gestellt",
          value: "Wird im Schrank produziert und gepuffert und für extern zur Verfügung gestellt"
        },
      ],
      typ: [
        {
          label: "Messgerät mit Display",
          value: "Messgerät mit Display"
        },
        {
          label: "Messgerät ohne Display",
          value: "Messgerät ohne Display"
        }
      ],
      ezaSchutzeinrichtung: [
        {
          label: "Beistellung",
          value: "Beistellung"
        },
        {
          label: "Durch Phoenix Contact zu liefern",
          value: "Durch Phoenix Contact zu liefern"
        }
      ],
      einbauort: [
        {
          label: "MS-Anlage",
          value: "MS-Anlage"
        },
        {
          label: "Fernwirk- und Schutzschrank",
          value: "Fernwirk- und Schutzschrank"
        }
      ],
      kommunikationsprotokoll2: [
        {
          label: "IEC 60870-5-103",
          value: "IEC 60870-5-103"
        },
        {
          label: "ModbusRTU",
          value: "ModbusRTU"
        }
      ],
      modem: [
        {
          label: "Beistellung durch den AG",
          value: "Beistellung durch den AG"
        },
        {
          label: "Wird durch Phoenix Contact geliefert",
          value: "Wird durch Phoenix Contact geliefert"
        },
        {
          label: "Muss nicht Einbau berücksichtigt werden",
          value: "Muss nicht Einbau berücksichtigt werden"
        }
      ],
      hardwarelieferung: [
      {
        label: "Vollständiger Schaltschrank",
        value: "Vollständiger Schaltschrank"
      },
      {
        label: "Einzelne Komponenten",
        value: "Einzelne Komponenten"
      }
    ],
    }
  },

  methods: {

  }
})
export default class HomeView extends Vue {
  @Provide() project = {
    "project": {
      "name": "",
      "system_type": "",
      "travo_power": null,
      "grid_area": "",
      "postal_code": null,
      "offer_date": null,
      "realisation_date": null
    },
    "customer": {
      "company": "",
      "firstname": "",
      "surname": "",
      "mail": "",
    },
    "short_circuit_indicator": {
      "information": "",
      "manufacturer": "",
      "system_type": "",
      "protocol": "",
      "control": "",
    },
    "eza": {
      "information": "",
      "manufacturer": "",
      "type": "",
      "protocol": ""
    },
    "auxiliary_power": {
      "information": "",
      "buffer_time": null
    },
    "low_voltage_measurement": {
      "information": "",
      "manufacturer": "",
      "type": ""
    },
    "eza_protector": {
      "information": "",
      "protective_function": {
        "uf": false,
        "umz": false,
        "qu": false
      },
      "location": "",
      "manufacturer": "",
      "type": "",
      "protocol": ""
    },
    "grid_control_center": {
      "modem": "",
      "manufacturer": "",
      "type": ""
    },
    "services": {
      "eza": {
        "parameterisation": false,
        "commissioning": false
      },
      "eze": {
        "parameterisation": false,
        "commissioning": false
      },
      "short_circuit_indicator": {
        "parameterisation": false,
        "commissioning": false
      },
      "telecontrol": {
        "parameterisation": false,
        "commissioning": false
      },
      "eza_regulator": {
        "parameterisation": false,
        "commissioning": false
      },
      "converter": {
        "commissioning": false
      }
    },
    "delivery": "",
    "miscellaneous": ""
  }
  @Provide() status = {
    responseStatus: 0,
    send: false
  }


  async send() {
    this.status.send = true;
    const url = `${window.location.origin}/api/v1/project`;
    const options = {
      method: "PUT",
      body: JSON.stringify(this.project)
    }

    try {
      let response = await fetch(url, options);
      console.log("success")
      this.status.responseStatus = response.status;
      this.status.send = false;
      window.scrollTo({top: 0, behavior: "smooth"})
    } catch( exception ) {
      this.status.responseStatus = -1;
      this.status.send = false;
      console.error({exception});
      window.scrollTo({top: 0, behavior: "smooth"})
    }
  }
}

